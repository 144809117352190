const breakpoints = ["40em", "52em", "64em"]

export default {
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#435572",
    muted: "#D1D5DB",
    secondary: "#1F2937",
    accent: "#9CA3AF",
  },
  fonts: {
    body: "'Work Sans','Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  fontSizes: ["0.8em", "1em"],
  lineHeights: {
    body: 1.5,
  },
  sizes: {
    container: "960px",
  },
  space: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em"],
  text: {
    link: {
      color: "inherit",
      textDecoration: "none",
      borderBottom: "1px solid",
      borderColor: "inherit",
      transition: "color 100ms ease-in-out",
    },
    linkDisabled: {
      textDecoration: "none",
      opacity: "0.4",
      pointerEvents: "none",
    },
  },
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
}
