/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"

import transition from "../utils/framer-transition"

const slabMotionVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition },
  },
}
const Slab = ({ first, bg, color, children, footer, slabSx, useTheme }) => {
  const Tag = footer ? motion.footer : motion.section

  return (
    <Tag
      className={footer ? "slab-footer" : "slab-standard"}
      variants={slabMotionVariants}
      sx={{
        bg: bg,
        color: color,
        overflow: "auto",
        pt: first ? 5 : 3,
        pb: first ? 3 : 3,
        ...slabSx,
        "a:hover": {
          color: bg === "background" || bg === "muted" ? "primary" : "accent",
        },
      }}
    >
      {children}
    </Tag>
  )
}

Slab.defaultProps = {
  bg: "background",
  color: "text",
  footer: false,
}

export default Slab
