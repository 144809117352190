/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, withPrefix } from "gatsby"
import shortid from "shortid"
import { motion } from "framer-motion"

import transition from "../utils/framer-transition"

const textVariants = {
  initial: { x: -60, opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: {
    x: -60,
    opacity: 0,
    transition: { ...transition },
  }
}

const LinkList = ({ links, parentPrefix, listSx }) => {
  return (
    <motion.ul
      variants={textVariants}
      sx={{
        listStyleType: "none",
        li: {
          borderBottom: "1px solid",
          borderColor: "inherit",
          "&:first-of-type a": {
            paddingTop: "0px",
          },
        },

        a: {
          textDecoration: "none",
          display: "block",
          py: 0,
        },
        ...listSx,
      }}
    >
      {links.map(link => (
        <motion.li variants={textVariants} key={`link-to-${link.slug}`}>
          <Link to={`${parentPrefix}${withPrefix(link.slug)}`}>
            {link.title}
          </Link>
        </motion.li>
      ))}
    </motion.ul>
  )
}

LinkList.defaultProps = {
  parentPrefix: ``,
}

export default LinkList
