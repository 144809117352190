const prefersReducedMotion =
  typeof window === "undefined"
    ? false
    : window.matchMedia("(prefers-reduced-motion: reduce)").matches

export default {
  duration: prefersReducedMotion ? 0 : 0.5,
  ease: [0.43, 0.13, 0.23, 0.96],
  staggerChildren: prefersReducedMotion ? 0 : 0.08,
}
