/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React from "react"

import Slab from "./slab"
import Container from "./container"
import LinkList from "../components/link-list"

const Footer = ({ footerLinks }) => (
  <>
    {footerLinks.length > 0 && (
      <Slab bg="secondary" color="background" footer>
        <Container>
          <LinkList links={footerLinks} />
        </Container>
      </Slab>
    )}
  </>
)

Footer.defaultProps = {
  footerLinks: [],
}

export default Footer
