/** @jsx jsx */
import { jsx } from "theme-ui"

export default props => (
  <div
    {...props}
    sx={{
      maxWidth: "container",
      width: "100%",
      mx: "auto",
      px: 0,
    }}
  />
)
