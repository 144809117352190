/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React, { useState, useCallback, useRef } from "react"
import { AnimatePresence } from "framer-motion"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"

import GlobalStyles from "./global-styles"
import HeaderContext from "../context/header-context"

import Footer from "./footer"

const PageWrapper = ({ children }) => {
  const initialHeaderState = useRef({
    inverseHeaderColor: false,
    isHome: false,
    prevPath: null,
    section: null,
    sectionLink: null,
    menuLinkHoverColor: "primary",
  })

  const [headerState, setHeaderState] = useState({
    ...initialHeaderState.current,
  })

  const updateHeaderState = useCallback(headerOpts => {
    setHeaderState({
      ...initialHeaderState.current,
      ...headerOpts,
    })
  }, [])

  const [slabState, setSlabState] = useState({
    backgroundColor: "primary",
    slabHeight: 0,
  })

  return (
    <>
      <GlobalStyles />
      <SkipNavLink />
      <HeaderContext.Provider
        value={{
          headerState,
          updateHeaderState,
          slabState,
          setSlabState,
        }}
      >
        <SkipNavContent />
        <AnimatePresence exitBeforeEnter>
          {children}
        </AnimatePresence>
      </HeaderContext.Provider>
      <Footer />
    </>
  )
}

export default PageWrapper
