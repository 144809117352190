/** @jsx jsx */
import { jsx } from "theme-ui"
import { Global } from "@emotion/core"

// Fonts via Google Fonts
import "@fontsource/work-sans/400.css"
import "@fontsource/work-sans/600.css"

export default () => (
  <Global
    styles={theme => ({
      "html, body": {
        height: "100%",
      },
      body: {
        fontFamily: theme.fonts.body,
        fontSize: "18px",
        [`${theme.mediaQueries.medium}`]: {
          fontSize: "22px",
        },
      },
      "::selection": {
        backgroundColor: theme.colors.primary,
        color: "white",
      },
      "*, :after, :before": {
        boxSizing: "border-box",
        backgroundColor: "inherit",
        fontFamily: "inherit",
        color: "inherit",
        margin: 0,
        padding: 0,
        border: "0 solid",
      },
      "[data-reach-skip-link]": {
        border: "0px",
        clip: "rect(0 0 0 0)",
        height: "1px",
        width: "1px",
        margin: "-1px",
        padding: "0",
        overflow: "hidden",
        position: "absolute",
        backgroundColor: theme.colors.primary,
        color: theme.colors.background,
        fontSize: theme.fontSizes[1],
        textDecoration: "none",
        "&:focus": {
          padding: theme.space[0],
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10,
          width: "auto",
          height: "auto",
          clip: "auto",
        },
      },
      pre: {
        padding: theme.space[0],
        overflow: "auto",
      },
    })}
  />
)
